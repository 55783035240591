import React from "react";
import {MagnetoNotFound} from '../../components/svgs/magneto-lito';
import { Container, Grid } from "@material-ui/core";
import { makeStyles } from '@material-ui/core/styles';
import { colors } from "../../style/constants";

const useStyles = makeStyles((theme) => ({
  wrapper: {
    textAlign: 'center',
    background: colors.ATT_BLUE,
    padding: '60px 0 90px 0',
    marginTop: -60,
    [theme.breakpoints.down('xs')]: {
      padding: '30px 0 60px 0',
      marginTop: -30,
    },
    '& h1': {
      color: colors.WHITE,
      marginTop: 30,
    }
  },
}));

const NotFoundPage = (props) => {
  const classes = useStyles();
  return (
    <div className={classes.wrapper}>
      <Container>
        <Grid container justifyContent='center' alignItems='center'>
          <Grid container item lg={4} md={6} xs={8} justifyContent='center' alignItems='center'>
            <MagnetoNotFound/>
          </Grid>
        </Grid>
        <h1>{props.lang === 'fr-ca' ? "PAGE INTROUVABLE" : "PAGE NOT FOUND"}</h1>
      </Container>
    </div>
  )
}

export default NotFoundPage
