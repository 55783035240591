import React from "react";
import Layout from "../components/layout";
import Seo from "../components/seo";
import NotFoundPage from "../layouts/404";

const NotFoundPageFr = () => {
  return (
    <Layout 
    lang='fr-CA' 
    switchLink='/en/404/'
    slider={false} >
      <Seo title="Page introuvable" />
      <NotFoundPage lang='fr-ca'/>
    </Layout>
  )
}

export default NotFoundPageFr
